<template>
    <div class="mypage_curation_receive_wrap">
        <!--       받은 의뢰 리스트 레이아웃-->
        <table class="my_history_table" width="100%"  border="0" cellspacing="0" cellpadding="0"
               style = "table-layout: auto; width: 1200px; table-layout: fixed;">
            <colgroup>
                <col width="95px" />
                <col width="143px" />
<!--                <col width="220px" />
                <col width="160px" />
                <col width="125px" />
                <col width="130px" />
                <col width="155px" />-->
                <col width="250px" />
                <col width="190px" />
                <col width="155px" />
<!--                <col width="130px" />-->
                <col width="185px" />
                <col width="140px" />
            </colgroup>
            <tr class="title_row">
                <th colspan="1">No</th>
                <th colspan="1">{{$t('image')}}</th>
                <th colspan="1">{{$t('subject')}}</th>
                <th colspan="1">{{$t('client')}}</th>
                <th colspan="1">{{$t('mini_sell')}}</th>
<!--                <th colspan="1">{{$t('a_curation')}}</th>-->
                <th colspan="1">{{$t('r_date')}}</th>
                <th colspan="1">{{$t('status')}}</th>
            </tr>
            <tr v-for="(curation, index) in curationData" :key="`curation${curation.ibc_idx}`">
                <td>{{ tableIdx(index) }}</td>  <!-- No -->
                <td class="cursor" @click="moveItemDetail(curation)">
                    <img :src="returnItemImage(curation)" width="78" height="49"/>
                </td>
                <td class="t_subject">{{ curation.i_name }}</td>  <!-- 아이템명 -->
                <td>{{ curation.ibc_applicant_nick }}</td>  <!-- 의뢰인 -->

                <td>{{ numFormat(curation.ibc_price) }}</td> <!-- 최소 판매금액 -->

<!--                <td>
                    퍼블 들어가시는 분이 삭제해주세요.
                </td> &lt;!&ndash; 큐레이션 비용 &ndash;&gt;-->

                <td v-html="returnDateFormat(curation.created_at, 'YYYY-MM-DD HH:MM')"></td>  <!-- 의뢰일시 -->

                <td >
                    <div v-if="returnStatusData(curation.ibc_status).hasCancel">
                        <button class="startBtn" @click="openAllowModal(curation)">{{$t('accept')}}</button>
                        <button class="endBtn ma5 ma-right-none" @click="cancelRequest(curation)">{{$t('refuse')}}</button>
                    </div>
                    <span v-else>{{$t(returnStatusData(curation.ibc_status).showName)}}</span>
                </td> <!-- 상태 -->
            </tr>
        </table>

        <mypage-request-modal></mypage-request-modal>
    </div>
</template>

<script>
import alertMixins from "@/mixins/alertMixins";
import imageOption from "@/mixins/imageOption";
import dateMixins from "@/mixins/dateMixins";
import {curationStatus} from "@/service/curationService";
import util from "@/mixins/util";
import EventBus from "@/utils/event-bus";
import MypageRequestModal from "@/components/modal/MypageRequestModal";
import CurationTermsModal from "@/components/modal/CurationTermsModal";
import TermsModal from "@/components/modal/TermsModal";
import imageResizeMixins from "@/mixins/imageResizeMixins";
export default {
    name: "CurationListReceiveLayout",
    mixins: [alertMixins, imageOption, dateMixins,imageResizeMixins],
    components: {MypageRequestModal},
    inject: [],
    provide() {
        return {}
    },
    props: {
        curationData: {default: () => []},
        paginationData: {default: {page: 1, size: 10, total: 0}},
    },
    data() {
        return {
            curationLayouts: 10,
            tempSubject: this.$t('temp_info')
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        movePage(url) {
            this.$router.push(`${this.$route.path}/${url}`)
        },
        moveItemDetail(curation) {
            let url = `/item/${curation.i_idx}`
            if(curation.im_status === 2 || curation.im_status === 5) {
                url = `/premiummarket/${curation.i_idx}`
            }else if(curation.im_status === 1 || curation.im_status === 3 || curation.im_status === 4) {
                url = `/openmarket/${curation.i_idx}`
            }
            this.$router.push(url);
        },
        tableIdx(index) {
            return this.paginationData.total - index - (this.paginationData.page - 1) * this.paginationData.size;
        },
        returnItemImage(curation) {
            if(util.isEmpty(curation.ItemFile) || util.isEmpty(curation.ItemFile.Item)) {
                return "";
            }
            return `${curation.ItemFile.Item[0].org_url}?${this.setImageOptions(78, 49, this.returnExtension(curation.ItemFile.Item[0].org_url))}`;
        },
        numFormat(num) {
            if(util.isEmpty(num) || isNaN(num)) {
                return 0;
            }
            return util.Number.numFormat(num);
        },
        returnStatusData(value) {
            let status = {value: null, name: '-', showName: '-', hasCancel: false,};
            let index = curationStatus.findIndex(st => st.value === value);
            if(index > -1) {
                status = curationStatus[index];
            }
            return status;
        },
        openAllowModal(curation) {
            this.$modal.show('mypage-request-modal', {curationData: curation});
        },
        /*    acceptConfirm() {
              this.createConfirm({
                title: '',
                content: " '<span class='subject'>"+ this.tempSubject + "</span>'<br>" +
                    this.$t('mypage_accept_confirm') +
                    '<br>'+this.$t('in_process')+' <a href='+"#"+'> '+this.$t('terms_curation') +'</a>'+this.$t('mypage_accept_confirm2'),
                confirmText : this.$t('yes'),
                cancelText : this.$t('cancel'),
                confirm: () => {

                },
                cancel: () => {

                }
              })
            },*/
        cancelRequest(curation) {
            this.createConfirm({
                title: '',
                content: " '<span class='subject'>"+ curation.i_name + "</span>'<br>" +
                    this.$t('mypage_refuse_confirm'),
                confirmText : this.$t('yes'),
                cancelText : this.$t('cancel'),
                confirm: () => {
                    EventBus.$emit('curationListReject', curation);
                },
            })
        },

    },
    watch: {},
}
</script>

<style scoped>

</style>
